.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.exchange-detail .detail-back {
  width: 100%;
  height: 2.4rem;
  background: var(--main-color);
  display: flex;
  justify-content: center;
  align-content: center;
}
.exchange-detail .detail-back .back-cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 3rem;
}
.exchange-detail .detail-back .back-cont img {
  width: 0.96rem;
  height: 0.96rem;
}
.exchange-detail .detail-back .back-cont .txt {
  font-size: 0.28rem;
  color: #fff;
}
.exchange-detail .detail-cont {
  width: 100%;
}
.exchange-detail .detail-cont .item-cont {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.28rem;
  color: #666;
}
.exchange-detail .detail-cont .account {
  font-size: 0.28rem;
  color: #666;
  padding: 0 0.1rem;
  margin-top: 0.2rem;
  background: #fff;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}
.exchange-detail .detail-cont .account .txt {
  float: left;
  padding: 0.2rem 0;
}
.exchange-detail .detail-cont .account .number {
  float: right;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 0.6rem;
}
.exchange-detail .detail-cont .detail-item {
  padding: 0.1rem 0.1rem 0 0.1rem;
  background: #fff;
}
.exchange-detail .detail-cont .detail-item .layout-bottom {
  position: relative;
  overflow: hidden;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img {
  float: left;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img img {
  width: 0.96rem;
  height: 0.96rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt {
  height: 0.96rem;
  margin-left: 0.2rem;
  float: left;
  position: relative;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .number {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  bottom: 0.05rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .name {
  font-size: 0.28rem;
  color: #333;
  padding-top: 0.09rem;
}
.exchange-detail .detail-cont .detail-item .border {
  border-bottom: 0.01rem solid #BBB;
}
.exchange-detail .order-btn {
  width: 100%;
  overflow: hidden;
  background: #fff;
  position: fixed;
  bottom: 0;
}
.exchange-detail .order-btn .common {
  float: right;
  margin: 0.2rem 0;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  margin-right: 0.3rem;
}
.exchange-detail .order-btn .concel {
  color: #666;
}
.exchange-detail .order-btn .confirm {
  background: var(--main-color);
  color: #fff;
}
