.border-none[data-v-592d3d90]:before {
  display: none;
}
.border-none[data-v-592d3d90]:after {
  display: none;
}
.flex-fix[data-v-592d3d90] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-592d3d90]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-592d3d90] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-592d3d90] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-592d3d90] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-592d3d90] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-592d3d90] {
  *zoom: 1;
}
.clear-fix[data-v-592d3d90]:before,
.clear-fix[data-v-592d3d90]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.exchange-detail .detail-back[data-v-592d3d90] {
  width: 100%;
  height: 2.4rem;
  background: var(--main-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
}
.exchange-detail .detail-back .back-cont[data-v-592d3d90] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 3rem;
}
.exchange-detail .detail-back .back-cont img[data-v-592d3d90] {
  width: 0.96rem;
  height: 0.96rem;
}
.exchange-detail .detail-back .back-cont .txt[data-v-592d3d90] {
  font-size: 0.28rem;
  color: #fff;
}
.exchange-detail .detail-cont[data-v-592d3d90] {
  width: 100%;
}
.exchange-detail .detail-cont .item-cont[data-v-592d3d90] {
  width: 100%;
  height: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.28rem;
  color: #666;
}
.exchange-detail .detail-cont .account[data-v-592d3d90] {
  font-size: 0.28rem;
  color: #666;
  padding: 0 0.1rem;
  margin-top: 0.2rem;
  background: #fff;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
}
.exchange-detail .detail-cont .account .txt[data-v-592d3d90] {
  float: left;
  padding: 0.2rem 0;
}
.exchange-detail .detail-cont .account .number[data-v-592d3d90] {
  float: right;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 0.6rem;
}
.exchange-detail .detail-cont .detail-item[data-v-592d3d90] {
  padding: 0.1rem 0.1rem 0 0.1rem;
  background: #fff;
}
.exchange-detail .detail-cont .detail-item .layout-bottom[data-v-592d3d90] {
  position: relative;
  overflow: hidden;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img[data-v-592d3d90] {
  float: left;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img img[data-v-592d3d90] {
  width: 0.96rem;
  height: 0.96rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt[data-v-592d3d90] {
  height: 0.96rem;
  margin-left: 0.2rem;
  float: left;
  position: relative;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .number[data-v-592d3d90] {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  bottom: 0.05rem;
}
.exchange-detail .detail-cont .detail-item .layout-bottom .img-txt .name[data-v-592d3d90] {
  font-size: 0.28rem;
  color: #333;
  padding-top: 0.09rem;
}
.exchange-detail .detail-cont .detail-item .border[data-v-592d3d90] {
  border-bottom: 0.01rem solid #BBB;
}
.exchange-detail .order-btn[data-v-592d3d90] {
  width: 100%;
  overflow: hidden;
  background: #fff;
  position: fixed;
  bottom: 0;
}
.exchange-detail .order-btn .common[data-v-592d3d90] {
  float: right;
  margin: 0.2rem 0;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  margin-right: 0.3rem;
}
.exchange-detail .order-btn .concel[data-v-592d3d90] {
  color: #666;
}
.exchange-detail .order-btn .confirm[data-v-592d3d90] {
  background: var(--main-color);
  color: #fff;
}
